/**
 * Generates a unique identifier (UID) as a string.
 * @returns A unique identifier.
 */
export const uid = (): string => {
  return 'xxxxxxxxxx4xxxyxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

import React from 'react';
import { PageComponent } from '@wap-client/constants/types';

import nodes from '@/components';
import { generator } from '@/utils';

export const render = (
  components: PageComponent[],
  views: Record<string, PageComponent[]>
) => {
  if (!components || !components.length) return null;

  return components.map((component) => {
    const { id, name, props, refs, children } = component;
    const key = `${id}-${generator.uid()}`;

    if (name === 'view') {
      const view = views[id];

      if (view) {
        return <React.Fragment key={key}>{render(view, views)}</React.Fragment>;
      }
    } else {
      const Component = nodes(name);

      if (Component) {
        if (children.length) {
          return (
            <Component key={key} {...refs} {...props} id={id}>
              {render(children, views)}
            </Component>
          );
        } else {
          return <Component key={key} {...refs} {...props} id={id} />;
        }
      }

      if (process.env.NEXT_PUBLIC_ENV === 'development') {
        console.log(`Missing component definitions (${name}): `, {
          props,
          refs,
        });
      }
    }
  });
};

export default render;

import React from 'react';
import Head from 'next/head';
import { path } from '@wap-client/core';

import { SeoMetaProps } from './types';

const SeoMeta: React.FunctionComponent<SeoMetaProps> = ({
  context: { page, settings, environment },
}) => {
  const suffix = `- ${settings.shortName}`;
  const title = page.metatags.title || `${page.title} ${suffix}`;
  const description = page.metatags.description || title;
  const url = path.url(environment, page.path);

  const renderTitle = () => <title>{title}</title>;

  const renderMetaDescription = () => {
    return <meta name="description" content={description} />;
  };

  const renderMetaOg = () => {
    const { opengraph } = page.metatags;

    return (
      <React.Fragment>
        <meta property="og:title" content={opengraph?.title || title} />
        <meta
          property="og:description"
          content={opengraph?.description || description}
        />
        <meta
          property="og:site_name"
          content={settings.title || settings.shortName}
        />
        <meta
          property="og:image"
          content={path.asset(
            environment,
            opengraph?.image?.src || page.image?.src
          )}
        />
        <meta property="og:url" content={url} />
      </React.Fragment>
    );
  };

  const renderMetaGoogle = () => {
    const { opengraph } = page.metatags;

    return (
      <React.Fragment>
        <meta itemProp="name" content={opengraph?.title || title} />
        <meta
          itemProp="image"
          content={path.asset(
            environment,
            opengraph?.image?.src || page.image?.src
          )}
        />
      </React.Fragment>
    );
  };

  const renderMetaTwitter = () => {
    const { twitter } = page.metatags;

    return (
      <React.Fragment>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={twitter?.title || title} />
        <meta name="twitter:site" content={twitter?.site || url} />
        <meta
          name="twitter:description"
          content={twitter?.summary || twitter?.description || description}
        />
        <meta
          name="twitter:image:src"
          content={path.asset(
            environment,
            twitter?.summaryImage?.src || twitter?.image?.src || page.image?.src
          )}
        />
      </React.Fragment>
    );
  };

  const renderMetaOptions = () => {
    const content = [];

    if (page.metatags.isNoIndex) {
      content.push('noindex');
    }

    if (page.metatags.isNoFollow) {
      content.push('nofollow');
    }

    if (!content.length) return null;

    return <meta name="robots" content={content.join(',')} />;
  };

  return (
    <Head>
      {renderTitle()}
      {renderMetaDescription()}
      {renderMetaOg()}
      {renderMetaGoogle()}
      {renderMetaTwitter()}
      {renderMetaOptions()}
    </Head>
  );
};

export default SeoMeta;

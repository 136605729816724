import dynamic from 'next/dynamic';

import { Container, Row, Column } from './base/gridview';

const Button = dynamic(() => import('./base/button'));
const Heading = dynamic(() => import('./base/heading'));
const Html = dynamic(() => import('./base/html'));
const Section = dynamic(() => import('./base/section'));
const Image = dynamic(() => import('./base/image'));
const Video = dynamic(() => import('./base/video'));
const Icon = dynamic(() => import('./base/icon'));

const Header = dynamic(() => import('./widgets/header'));
const Footer = dynamic(() => import('./widgets/footer'));
const SliderHero = dynamic(() => import('./widgets/slider-hero'));
const Weather = dynamic(() => import('./widgets/weather'));
const Discover = dynamic(() => import('./widgets/discover'));
const PraticalInformation = dynamic(
  () => import('./widgets/pratical-information')
);
const WeatherDetail = dynamic(() => import('./widgets/weather/weather-detail'));
const CustomSection = dynamic(() => import('./widgets/custom-section'));
const Accordions = dynamic(() => import('./widgets/accordions'));
const SliderCards = dynamic(() => import('./widgets/slider-cards'));
const BreadCrumbs = dynamic(
  () => import('./widgets/page-detail-content/breadcrumbs')
);
const DropdownFilter = dynamic(() => import('./widgets/dropdown-filter'));
const MpNavigation = dynamic(
  () => import('./widgets/header/mobile-panel/mp-navigation')
);

const Welcome = dynamic(() => import('./widgets/welcome'));
const TextBanner = dynamic(() => import('./widgets/text-banner'));
const Banner = dynamic(() => import('./widgets/banner'));
const BuildRoutes = dynamic(() => import('./widgets/build-routes'));
const PromoBanner = dynamic(() => import('./widgets/promo-banner'));
const Sustainability = dynamic(() => import('./widgets/sustainability'));
const MPNowPopular = dynamic(
  () => import('./widgets/header/mobile-panel/mp-now-popular')
);
const PageDetailContent = dynamic(
  () => import('./widgets/page-detail-content')
);
const TripDays = dynamic(() => import('./widgets/trip-days'));
const PlanYourTrip = dynamic(() => import('./widgets/plan-your-trip'));
const MapSimple = dynamic(() => import('./widgets/map-simple'));
const MapEasyRoute = dynamic(() => import('./widgets/map-easy-route'));
const MapLocation = dynamic(() => import('./widgets/map-location'));
const MapLocationSingle = dynamic(
  () => import('./widgets/map-location-single')
);
const MPSecondNavigation = dynamic(
  () => import('./widgets/header/mobile-panel/mp-second-navigation')
);
const MPHeaderBar = dynamic(
  () => import('./widgets/header/mobile-panel/mp-header-bar')
);
const MPPopularStories = dynamic(
  () => import('./widgets/header/mobile-panel/mp-popular-stories')
);
const NavigationAction = dynamic(
  () => import('./widgets/page-detail-content/navigation-actions')
);
const FindYourPerfectEscape = dynamic(
  () => import('./widgets/find-your-perfect-escape')
);
const CategorySlider = dynamic(() => import('./widgets/category-slider'));
const Headline = dynamic(() => import('./widgets/headline'));
const CustomButton = dynamic(() => import('./widgets/custom-button'));
const StickyTripPlanButton = dynamic(
  () => import('./widgets/sticky-trip-plan-button')
);
const DestinationFinder = dynamic(() => import('./widgets/destination-finder'));
const DropdownFilterForm = dynamic(
  () => import('./widgets/dropdown-filter/dropdown-filter-form')
);
const SubRoutes = dynamic(() => import('./widgets/sub-routes'));
const WeatherByCity = dynamic(
  () => import('./widgets/weather/weather-by-city')
);
const TagList = dynamic(() => import('./widgets/tag-list'));
const CardBanner = dynamic(() => import('./widgets/card-banner'));
const CardProfile = dynamic(() => import('./widgets/card-profile'));
const CardInfo = dynamic(() => import('./widgets/card-info'));
const DoubleBanner = dynamic(() => import('./widgets/double-banner'));
const CustomTitle = dynamic(() => import('./widgets/custom-title'));
const Newsletter = dynamic(() => import('./widgets/newsletter'));
const PromoBannerContent = dynamic(
  () => import('./widgets/promo-banner-content')
);
const FeatureCard = dynamic(() => import('./widgets/feature-card'));
const ArticleCard = dynamic(() => import('./widgets/article-card'));
const QuartetCard = dynamic(() => import('./widgets/quartet-card'));
const CardList = dynamic(() => import('./widgets/card-list'));
const CardListTest = dynamic(() => import('./widgets/card-list-test'));
const BlogItem = dynamic(() => import('./widgets/blog-item'));
const SingleHero = dynamic(() => import('./widgets/single-hero'));
const Approval = dynamic(() => import('./widgets/approval'));
const MyProfile = dynamic(() => import('./widgets/my-profile'));
const BlogImage = dynamic(() => import('./widgets/blog-item/blog-image'));
const BlogText = dynamic(() => import('./widgets/blog-item/blog-text'));
const HeadlineV2 = dynamic(() => import('./widgets/headline-v2'));
const SliderCardsv2 = dynamic(() => import('./widgets/slider-cards-v2'));
const CategorySliderv2 = dynamic(() => import('./widgets/category-slider-v2'));
const BlogBanner = dynamic(() => import('./widgets/blog-item/blog-banner'));
const FeatureCardMeta = dynamic(() => import('./widgets/feature-card-meta'));
const BlogIFrame = dynamic(() => import('./widgets/blog-item/blog-iframe'));
const Favorites = dynamic(() => import('./widgets/favorites'));
const MpTitle = dynamic(() => import('./widgets/header/mobile-panel/mp-title'));
const BlogItemList = dynamic(
  () => import('./widgets/blog-item/blog-item-list')
);
const CardBannerV2 = dynamic(() => import('./widgets/card-banner-v2'));
const HeadlineV3 = dynamic(() => import('./widgets/headline-v3'));
const NotFound = dynamic(() => import('./widgets/not-found'));

export default function getter(name: string) {
  switch (name) {
    case 'container':
      return Container;
    case 'heading':
      return Heading;
    case 'html':
      return Html;
    case 'row':
      return Row;
    case 'column':
      return Column;
    case 'button':
      return Button;
    case 'image':
      return Image;
    case 'section':
      return Section;
    case 'video':
      return Video;
    case 'icon':
      return Icon;

    case 'gt-header':
      return Header;
    case 'gt-footer':
      return Footer;
    case 'gt-slider-hero':
      return SliderHero;
    case 'gt-weather':
      return Weather;
    case 'gt-discover':
      return Discover;
    case 'gt-pratical-information':
      return PraticalInformation;
    case 'gt-weather-detail':
      return WeatherDetail;
    case 'gt-section':
      return CustomSection;
    case 'gt-accordions':
      return Accordions;
    case 'gt-slider-cards':
      return SliderCards;
    case 'gt-bread-crumbs':
      return BreadCrumbs;

    case 'gt-dropdown-filter':
      return DropdownFilter;
    case 'gt-mp-navigation':
      return MpNavigation;
    case 'gt-mp-now-popular':
      return MPNowPopular;
    case 'gt-mp-second-navigation':
      return MPSecondNavigation;
    case 'gt-mp-header-bar':
      return MPHeaderBar;

    case 'gt-welcome':
      return Welcome;
    case 'gt-text-banner':
      return TextBanner;
    case 'gt-banner':
      return Banner;
    case 'gt-build-routes':
      return BuildRoutes;
    case 'gt-promo-banner':
      return PromoBanner;
    case 'gt-sustainability':
      return Sustainability;
    case 'gt-mp-popular-stories':
      return MPPopularStories;

    case 'gt-trip-days':
      return TripDays;
    case 'gt-plan-your-trip':
      return PlanYourTrip;
    case 'gt-map-simple':
      return MapSimple;
    case 'gt-map-location':
      return MapLocation;
    case 'gt-map-location-single':
      return MapLocationSingle;
    case 'gt-map-easy-route':
      return MapEasyRoute;
    case 'gt-page-detail-content':
      return PageDetailContent;
    case 'gt-navigation-actions':
      return NavigationAction;
    case 'gt-find-your-perfect-escape':
      return FindYourPerfectEscape;
    case 'gt-category-slider':
      return CategorySlider;
    case 'gt-headline':
      return Headline;
    case 'gt-custom-button':
      return CustomButton;
    case 'gt-sticky-trip-plan-button':
      return StickyTripPlanButton;
    case 'gt-destination-finder':
      return DestinationFinder;
    case 'gt-dropdown-filter-form':
      return DropdownFilterForm;
    case 'gt-sub-routes':
      return SubRoutes;
    case 'gt-weather-by-city':
      return WeatherByCity;
    case 'gt-tag-list':
      return TagList;
    case 'gt-card-banner':
      return CardBanner;
    case 'gt-card-profile':
      return CardProfile;
    case 'gt-card-info':
      return CardInfo;
    case 'gt-double-banner':
      return DoubleBanner;
    case 'gt-custom-title':
      return CustomTitle;
    case 'gt-newsletter':
      return Newsletter;
    case 'gt-promo-banner-content':
      return PromoBannerContent;
    case 'gt-feature-card':
      return FeatureCard;
    case 'gt-article-card':
      return ArticleCard;
    case 'gt-quartet-card':
      return QuartetCard;
    case 'gt-card-list':
      return CardList;
    case 'gt-card-list-test':
      return CardListTest;
    case 'gt-blog-item':
      return BlogItem;
    case 'gt-single-hero':
      return SingleHero;
    case 'gt-approval':
      return Approval;
    case 'gt-myprofile':
      return MyProfile;
    case 'gt-blog-image':
      return BlogImage;
    case 'gt-blog-text':
      return BlogText;
    case 'gt-headline-v2':
      return HeadlineV2;
    case 'gt-category-slider-v2':
      return CategorySliderv2;
    case 'gt-slider-cards-v2':
      return SliderCardsv2;
    case 'gt-blog-banner':
      return BlogBanner;
    case 'gt-feature-card-meta':
      return FeatureCardMeta;
    case 'gt-blog-iframe':
      return BlogIFrame;
    case 'gt-favorites':
      return Favorites;
    case 'gt-mp-title':
      return MpTitle;
    case 'gt-blog-item-list':
      return BlogItemList;
    case 'gt-card-banner-v2':
      return CardBannerV2;
    case 'gt-headline-v3':
      return HeadlineV3;
    case 'gt-not-found':
      return NotFound;
  }
}

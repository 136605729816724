import { functions } from '@wap-client/core';
import type { ColumnProps, ColumnObject } from './types';
import { motion } from 'framer-motion';

const Column: React.FunctionComponent<ColumnProps> = ({
  motion: motionProps,
  className,
  xs,
  sm,
  md,
  lg,
  xl,
  align,
  offset,
  order,
  size,
  children,
  testId,
  auto,
  ...rest
}): JSX.Element => {
  const classNames = ['col'];

  const createClassName = (breakpoint: string, props: ColumnObject = {}) => {
    for (const prop in props) {
      const value = props[prop as keyof ColumnObject];

      if (value !== undefined) {
        if (prop === 'size') {
          classNames.push(`col${breakpoint}-${value}`);
        }
        if (prop === 'auto') {
          if (value) {
            classNames.push(`col${breakpoint}-${prop}`);
          }
        } else {
          classNames.push(`col${breakpoint}-${prop}-${value}`);
        }
      }
    }
  };

  createClassName('', { size, offset, align, order });
  createClassName('-xs', xs);
  createClassName('-sm', sm);
  createClassName('-md', md);
  createClassName('-lg', lg);
  createClassName('-xl', xl);

  if (motionProps) {
    return (
      <motion.div
        className={functions.classnames(...classNames, className)}
        {...motionProps}
        {...(rest as any)}
      >
        {children}
      </motion.div>
    );
  }

  return (
    <div
      className={functions.classnames(...classNames, className)}
      data-testid={testId}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Column;

import { functions } from '@wap-client/core';

import type { ContainerProps } from './types';

const Container: React.FunctionComponent<ContainerProps> = ({
  children,
  className,
  size = 'wide',
  ...rest
}) => {
  const classNames = functions.classnames(
    'container',
    `container-${size}`,
    className
  );

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};

export default Container;
